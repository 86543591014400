import PropTypes from 'prop-types';
import './Highlight.styles.css';

const Highlight = ({ text, searchTerm }) => {
  if (!searchTerm?.trim()) {
    return <span>{text}</span>;
  }

  const parts = text.split(new RegExp(`(${searchTerm})`, 'gi'));
  
  return (
    <span className="highlight-container">
      {parts.map((part, i) => 
        part.toLowerCase() === searchTerm.toLowerCase() ? 
          <mark key={i} className="highlight">{part}</mark> : 
          <span key={i}>{part}</span>
      )}
    </span>
  );
};

Highlight.propTypes = {
  text: PropTypes.string.isRequired,
  searchTerm: PropTypes.string.isRequired
};

export default Highlight;