import { useRef, useEffect } from 'react';
import '../styles/Features.styles.css';

function Features() {
  const imageRefs = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.2,
      rootMargin: '50px'
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in');
        }
      });
    }, observerOptions);

    imageRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  // Features data array - easy to add more features
  const features = [
    {
      title: "expand <span>impossible</span>",
      description: "often, the challenge lies not in your qualifications, but in showcasing them effectively. resumes written without recruiter expertise can miss the mark on what hiring managers are really looking for.",
      image: "/images/products/infinite-img-1.webp",
      imageAlt: "Expand impossible"
    },
    {
      title: "<span>compare</span> and <span>match</span> resume with any JD",
      description: "say goodbye to the time-consuming process of studying how to do it yourself. v a i r e e ensures that your resume catches every recruiter's eye and passes through any application tracking system algorithm seamlessly.",
      image: "/images/products/infinite-img-2.webp",
      imageAlt: "Match resume"
    }
  ];

  return (
    <section className="feature-sections">
      {features.map((feature, index) => (
        <div key={index} className="feature-section">
          <div className="container">
            <div className={`feature-content ${index % 2 === 1 ? 'feature-content-right' : ''}`}>
              <h2 dangerouslySetInnerHTML={{ __html: feature.title }} />
              <p>{feature.description}</p>
            </div>
            <div 
              className={`feature-image ${
                index % 2 === 0 ? 'slide-from-right' : 'slide-from-left'
              }`}
              ref={el => imageRefs.current[index] = el}
            >
              <img src={feature.image} alt={feature.imageAlt} />
            </div>
          </div>
        </div>
      ))}
    </section>
  );
}

export default Features;