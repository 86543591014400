import '../styles/Services.styles.css';

function Services() {
  return (
    <section className="services">
      <div className="container">
        <div className="services-header">
          <h2>v a i r e e</h2>
          <h3>your next gen career assistant</h3>
        </div>
        <div className="services-grid">
          <div className="service-card">
            <h4><span>fast</span> & simple</h4>
            <p>change your resume in just minutes! upload your document and get a professional analysis to make your resume catch every recruiter's eye.</p>
          </div>
          <div className="service-card">
            <h4>easy <span>integration</span></h4>
            <p>no need to rewrite everything! v a i r e e's feedback works seamlessly with your current resume to save you time.</p>
          </div>
          <div className="service-card">
            <h4><span>smart</span> resume analysis</h4>
            <p>get better feedback than generic tools! v a i r e e trains its ai like no other tool available online (as far as we know—we've tested 100+ tools) to help your resume stand out.</p>
          </div>
          <div className="service-card">
            <h4><span>match</span> job descriptions</h4>
            <p>quickly update your resume to fit any job description. with one click, save time and nerves while ensuring your application stands out.</p>
          </div>
          <div className="service-card">
            <h4>manage <span>applications</span></h4>
            <p>say goodbye to lost applications! v a i r e e helps you keep track of job applications and interviews, guiding you every step of the way.</p>
          </div>
          <div className="service-card">
            <h4>your career <span>booster</span></h4>
            <p>is this your best career move? v a i r e e is here to help you succeed. its insights connect you to great job opportunities, assist with negotiations, and help you reach your goals faster.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;