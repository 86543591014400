export const faqSections = [
  {
    title: "SIGN UP AND ACCOUNT",
    questions: [
      {
        q: "HOW CAN I CREATE AN ACCOUNT?",
        a: [
          "Currently, we support signing up exclusively through Google accounts. Once you sign up with your Google account, you can immediately access the application without needing to confirm your email or create a separate password. Please note that you may need to refresh your web browser to fully access the app. If you would like to see support for other platforms in the future, we encourage you to provide your feedback.",
          "Upon logging in, you will see your user account details at the top right of the screen, indicating that you have 1 free credit available."
        ]
      },
      {
        q: "HOW DO I SIGN UP FOR v a i r e e?",
        a: "Signing up for v a i r e e is simple. You sign up using your Google account, which means there's no need to create a separate password. After signing up, you might need to refresh your web browser to fully access the app. Once logged in, you will see your user account details at the top right of the screen, indicating that you have 1 free credit available."
      },
      {
        q: "WHAT HAPPENS WHEN I FIRST USE THE APPLICATION?",
        a: "When you enter the application for the first time, you receive one free credit. This allows you to use the app and experience its features. To continue using the app after this initial credit, you will need to subscribe to one of our plans."
      }
    ]
  },

  {
    title: "SUBSCRIPTION PLANS AND CREDIT SYSTEM",
    questions: [
      {
        q: "HOW DO CREDITS WORK ON v a i r e e?",
        a: [
          "Currently, using our resume services operates on a credit-based system:",
          "• Reviewing a resume costs 1 credit",
          "• Fixing a resume costs 2 credits",
          "Upon signing up, you automatically receive one free credit. This allows you to test our application and determine its value to you before making any commitments. We value your feedback and appreciate any insights you have about your experience."
        ]
      },
      {
        q: "WHAT IS THE CREDIT SYSTEM AT v a i r e e?",
        a: "At v a i r e e, we use a credit system to access our services. Credits allow you to utilise different features such as reviewing resumes, fixing resumes, and more. When you first sign up, you receive one free credit to explore the app and see its value. To make further moves or access additional features, you need to subscribe to one of our plans to receive more credits."
      },
      {
        q: "CAN I PURCHASE INDIVIDUAL CREDITS?",
        a: "Currently, it is not possible to purchase individual credits. You can only obtain credits through our subscription plans."
      },
      {
        q: "HOW DOES THE SUBSCRIPTION PLAN WORK FOR JOB SEEKERS?",
        a: [
          "We offer several subscription plans tailored for job seekers. You can choose to pay monthly or annually. Here's a breakdown of each plan:",
          {
            type: "heading",
            text: "PAY MONTHLY"
          },
          {
            type: "plan",
            title: "Job Seeker:",
            details: [
              "10 credits (review only)",
              "$10 / month",
              "You pay $10 each month, and you receive 10 credits each month. The credits reset monthly.",
              "The credits in this plan can only be used for reviewing and receiving professional feedback on your resume."
            ]
          },
          {
            type: "plan",
            title: "Job Seeker Pro:",
            details: [
              "20 credits + can fix resume",
              "$20 / month",
              "You pay $20 each month, and you receive 20 credits each month. The credits reset monthly.",
              "This plan allows for reviewing and improving your resume, as well as more comprehensive resume services, including fixing resumes."
            ]
          },
          {
            type: "plan",
            title: "Job Seeker Max:",
            details: [
              "80 credits + access to future features",
              "$80 / month",
              "You pay $80 each month, and you receive 80 credits each month. The credits reset monthly.",
              "This plan allows for reviewing and improving your resume, more comprehensive resume services, including fixing resumes, and access to upcoming features."
            ]
          },
          {
            type: "heading",
            text: "PAY ANNUALLY"
          },
          {
            type: "plan",
            title: "Job Seeker:",
            details: [
              "10 credits (review only)",
              {
                type: "price",
                original: "$10",
                discounted: "$8 / month"
              },
              "This means you pay $96 upfront, which equates to $8 per month, and your account will immediately receive 120 credits upon subscription. The credits can be used for reviewing/analyzing your resume.",
              "The credits in this plan can only be used for reviewing and receiving professional feedback on your resume."
            ]
          },
          {
            type: "plan",
            title: "Job Seeker Pro:",
            details: [
              "20 credits + can fix resume",
              {
                type: "price",
                original: "$20",
                discounted: "$16 / month"
              },
              "This means you pay $192 upfront, which equates to $16 per month, and your account will immediately receive 240 credits upon subscription. The credits can be used for reviewing and improving your resume.",
              "This plan allows for reviewing and improving your resume, as well as more comprehensive resume services, including fixing resumes."
            ]
          },
          {
            type: "plan",
            title: "Job Seeker Max:",
            details: [
              "80 credits + access to future features",
              {
                type: "price",
                original: "$60",
                discounted: "$64 / month"
              },
              "This means you pay $768 upfront, which equates to $64 per month, and your account will immediately receive 960 credits upon subscription. The credits can be used for reviewing and improving your resume.",
              "This plan allows for reviewing and improving your resume, more comprehensive resume services, including fixing resumes, and access to upcoming features."
            ]
          }
        ]
      },
      {
        q: "HOW DOES THE SUBSCRIPTION PLAN WORK FOR RECRUITERS?",
        a: [
          "We offer several subscription plans tailored for recruiters. You can choose to pay monthly or annually. Here’s a breakdown of each plan:",
          {
            type: "heading",
            text: "PAY MONTHLY"
          },
          {
            type: "plan",
            title: "Recruiter:",
            details: [
              "25 credits (review only)",
              "$25 / month",
              "You pay $25 each month, and you receive 25 credits each month. The credits reset monthly.",
              "The credits in this plan can only be used for reviewing and receiving professional feedback on any resume."
            ]
          },
          {
            type: "plan",
            title: "Recruiter Pro:",
            details: [
              "100 credits + API access",
              "$100 / month",
              "You pay $100 each month, and your account will immediately receive 100 credits upon subscription.",
              "This plan is ideal for recruiters needing extensive access and API integration."
            ]
          },
          {
            type: "plan",
            title: "Recruiter Max:",
            details: [
              "1000 credits + support",
              "$500 / month",
              "You pay $500 each month, and your account will immediately receive 1000 credits upon subscription.",
              "This plan offers the most credits and includes professional feedback and support, plus future features like comparing candidate's suitability for a job position."
            ]
          },
          {
            type: "heading",
            text: "PAY ANNUALLY"
          },
          {
            type: "plan",
            title: "Recruiter",
            details: [
              "25 credits (review only)",
              {
                type: "price",
                original: "$25",
                discounted: "$20 / month"
              },
              "This means you pay $240 upfront, which equates to $20 per month, and your account will immediately receive 300 credits upon subscription. The credits can be used for reviewing/analyzing resumes.",
              "The credits in this plan can only be used for reviewing and receiving professional feedback on any resume."
            ]
          },
          {
            type: "plan",
            title: "Recruiter Pro:",
            details: [
              "100 credits + API access",
              {
                type: "price",
                original: "$100",
                discounted: "$80 / month"
              },
              "This means you pay $960 upfront, which equates to $80 per month, and your account will immediately receive 1200 credits upon subscription.",
              "This plan is ideal for recruiters needing extensive access and API integration."
            ]
          },
          {
            type: "plan",
            title: "Recruiter Max:",
            details: [
              "1000 credits + support",
              {
                type: "price",
                original: "$500",
                discounted: "$400 / month"
              },
              "This means you pay $4,800 upfront, which equates to $400 per month, and your account will immediately receive 12000 credits upon subscription.",
              "This plan offers the most credits and includes professional feedback and support, plus future features like comparing suitability for a job position."
            ]
          }
        ]
      },
      {
        q: "WHAT IS THE SUBSCRIPTION PLAN CANCELLATION POLICY FOR JOB SEEKERS?",
        a: [
          "You can cancel your subscription at any time. Once you cancel, the service will stop functioning immediately, and you will no longer have access to your subscription benefits. No refunds will be provided for the remaining period of the billing cycle in which you cancel. To cancel your subscription:",
          "1. Go to your account settings.",
          "2. Select the 'Billing' tab.",
          "3. You will be redirected to Stripe, where your active subscription details are managed.",
          "4. Locate your active subscription and select the option to cancel it.",
          "If you encounter any issues or need assistance, please contact our support team."
        ]
      },
      {
        q: "WHAT ARE THE BENEFITS OF SUBSCRIBING TO A PLAN?",
        a: [
          "Subscribing to a plan provides you with a monthly allotment of credits that reset each month, allowing you to consistently access our services.",
          "It also offers cost savings compared to buying individual services, ensuring you receive more value for your investment."
        ]
      },
      {
        q: "WHAT IS INCLUDED IN THE “REVIEW ONLY” FEATURE OF THE JOB SEEKER PLAN?",
        a: "The \"review only\" feature means that the credits you receive can be used solely for reviewing/analyzing your resume. This includes receiving professional feedback to enhance your resume's effectiveness."
      },
      {
        q: "ARE THERE ANY LIMITATIONS ON THE NUMBER OF RESUMES I CAN REVIEW OR FIX EACH MONTH?",
        a: [
          "There are no specific limits on the number of resumes you can review or fix each month within the allocated credits. You can use your credits as per your needs until they are exhausted or reset.",
          "Currently, using our resume services operates on a credit-based system as follows:",
          "• Reviewing a resume costs 1 credit.",
          "• Fixing a resume costs 2 credits.",
          "However, please note that if you are on the Job Seeker plan, you can only use your credits for reviewing/analyzing resumes, not fixing them. The ability to fix resumes is available with the Job Seeker Pro plan and above. If you have no plan or are on the basic Job Seeker plan, you are limited to review-only functionalities."
        ]
      },
      {
        q: "HOW CAN I TRACK MY CREDIT USAGE AND REMAINING BALANCE?",
        a: "You can track your credit usage and remaining balance within your account settings. There, you'll find detailed information about how many credits are left in the current billing cycle."
      },
      {
        q: "WHAT HAPPENS IF I UPGRADE OR DOWNGRADE MY SUBSCRIPTION PLAN IN THE MIDDLE OF A BILLING CYCLE?",
        a: [
          "If you upgrade your subscription plan, the new plan's benefits, including additional credits, will take effect immediately, and you will be charged a prorated amount based on the remaining days in your current billing cycle.",
          "If you downgrade your plan, the change will also take effect immediately. This means your original plan is canceled immediately, and you lose its benefits while receiving the benefits of the downgraded plan right away. This immediate switch may result in a loss of some features and credits from your original plan.",
          "We recommend not downgrading your plan in the middle of a billing cycle. Instead, it's better to let your current plan run until the end of the cycle. For monthly subscriptions, you can cancel automatic monthly payments in the settings and then choose a new plan once the current monthly plan is finished:",
          "1. Go to your account settings.",
          "2. Select the \"Billing\" tab.",
          "3. You will be redirected to Stripe, where your active subscription details are managed.",
          "4. Locate your active subscription and select the option to cancel it."
        ]
      },
      {
        q: "HOW ARE PAYMENTS HANDLED FOR THE SUBSCRIPTION PLANS?",
        a: [
          "Payments for subscription plans can be handled either on a monthly or yearly basis. Here’s how it works:",
          "•Monthly plan: if you subscribe to a monthly plan, payments are made at the beginning of each monthly cycle. For example, if you choose the Job Seeker plan at $10 per month, you will be charged $10 at the start of each month cycle.",
          "•Yearly plan: if you choose to pay annually, you are charged the monthly cost of the plan times 12 upfront. For instance, with the Job Seeker plan at a discounted $8 per month when choosing the yearly plan, you will be immediately charged $8 x 12, totaling $96 for the year. You will be billed again at the end of this 12-month cycle.",
          "Important Notes:",
          "• If you do not wish to continue your subscription after the current billing cycle, make sure to cancel before the next payment is due.",
          "• Plan cancellation takes immediate effect. If you cancel a plan in the middle of a billing cycle, the benefits stop immediately, and you will lose access to the plan.",
          "• If you wish to keep the plan for the full 12 months but do not want to be charged again for another year, **you should not cancel during the 12-month period**. Canceling will result in immediate loss of access to the plan benefits. Instead, make sure to cancel right before the end of the current subscription period.",
          "• If you cancel by mistake, contact our support team immediately for assistance."
        ]
      },
      {
        q: "WHAT HAPPENS IF I DON'T USE ALL MY CREDITS IN A MONTH?",
        a: "Any unused credits do not roll over to the next month. Your credits reset at the beginning of each billing cycle, so it's best to use your allotted credits within the month."
      },
      {
        q: "WHAT HAPPENS IF I RUN OUT OF CREDITS BEFORE THE END OF THE MONTH?",
        a: "If you exhaust your credits before the end of your billing cycle, you can still access the application, but you won't be able to use the services that require credits until your credits reset at the beginning of the next billing cycle. You have the option to upgrade your plan or wait until your credits renew."
      },
      {
        q: "CAN I CHANGE MY SUBSCRIPTION PLAN?",
        a: [
          "Yes, you can change your subscription plan at any time. Simply contact our support team or adjust your plan settings within your account.",
          "Important Note:",
          "• Immediate effect: changes in subscription plans take immediate effect. This means if you upgrade or downgrade your plan, the new plan's benefits and credits will apply immediately. For example, if you downgrade your plan, the original plan is canceled immediately, and the downgraded plan takes effect right away. This will result in losing the benefits of the original plan and receiving the benefits of the downgraded plan immediately.",
          "• Recommendation: we recommend not downgrading your plan in the middle of a billing cycle. Instead, it’s better to let the current plan run its course until the end of the billing cycle. For monthly subscriptions, you can cancel automatic monthly payments in the settings, and once the current monthly plan finishes, you can choose a new plan as needed.",
          "Understanding this immediate effect is crucial to avoid any unintended disruption of services. If you downgrade by mistake, please contact us immediately for assistance."
        ]
      },
      {
        q: "WHAT SUPPORT IS AVAILABLE FOR RECRUITER PRO SUBSCRIBERS?",
        a: "Recruiter Pro subscribers receive professional feedback, best practices for implementing feedback effectively, and support for accessing future features like comparing job suitability."
      },
      {
        q: "HOW DO I CANCEL MY SUBSCRIPTION?",
        a: [
          "If you wish to cancel your subscription, you can do so at any time through your account settings. Here are the steps to cancel your subscription:",
          "1. Go to your account settings.",
          "2. Select the \"Billing\" tab.",
          "3. You will be redirected to Stripe, where your active subscription details are managed.",
          "4. Locate your active subscription and select the option to cancel it.",
          "Cancellation Policy: while you can cancel your subscription at any time, please be aware that the service will cease immediately upon cancellation. No refunds will be provided for the remaining period of the billing cycle in which you cancel. If you encounter any issues, please contact our support team for assistance."
        ]
      }
    ]
  },

  {
    title: "PAYMENT AND CREDIT CARD",
    questions: [
      {
        q: "DO YOU STORE CREDIT CARD INFORMATION SECURELY?",
        a: "We prioritise the security of your payment information. For handling transactions, we utilise Stripe, a trusted payment processing service known for its stringent security measures. Stripe manages the storage of credit card information securely, ensuring compliance with industry standards and regulations to protect your data."
      },
      {
        q: "WHAT PAYMENT SYSTEMS DO YOU WORK WITH?",
        a: "At v a i r e e, we work exclusively with Stripe for our payment processing needs. Stripe is renowned for its robust security measures and seamless transaction processing capabilities. This partnership allows us to ensure that all financial transactions conducted through our platform are handled securely and efficiently."
      }
    ]
  },

  {
    title: "USING v a i r e e",
    questions: [
      {
        q: "WHAT SHOULD I DO IF I DON'T HAVE A RESUME TO UPLOAD?",
        a: [
          "If you don't have a resume, you can either download your LinkedIn profile as a PDF (instructions are provided when uploading a resume) and upload it instead, or send an email to vairee@vairee.ai with the subject line 'RESUME CREATION REQUEST' to receive instructions on how to create a draft. Please note that due to capacity limitations, our response for creating drafts might be limited.",
          "Important Note: LinkedIn profiles are generally designed for business networking purposes and reflect the services a user is able to offer. However, they are rarely equivalent to a resume that follows the best conventions for job applications. To ensure the best relevancy and to get the most accurate analysis, we recommend requesting resume creation instructions. This ensures you use your free credit in the best way possible and get the most value from our app."
        ]
      },
      {
        q: "WHAT HAPPENS AFTER I UPLOAD MY RESUME?",
        a: "Once you upload your resume, the analysis process begins. Depending on the time and server load, especially during peak hours in America, this might take several minutes. Refreshing the page is not recommended as it doesn't speed up the process. If the problem persists, try again later. If you lose your free credit without receiving an analysis, contact support immediately with the subject 'LOST CREDITS' at vairee@vairee.ai or on our Discord channel (join https://discord.com/invite/TFpjCjdVJq )."
      },
      {
        q: "WHAT IF THE ANALYSIS PROCESS AFTER I UPLOAD MY RESUME TAKES TOO LONG?",
        a: [
          "If the analysis process after uploading your resume takes too long, it may be due to high traffic. We are continuously working hard to prevent any traffic jams and ensure smooth processing. However, during peak times, especially when users in America are active, the system might slow down.",
          "In such cases, you can either wait a bit longer or try again at a different time. Typically, the analysis should complete within a few minutes. Please avoid refreshing your browser during this process, as it will restart the analysis, further delay the completion and you might lose additional credit. If the problem persists, contact our support team immediately with the subject 'ANALYSIS TAKING TOO LONG' at vairee@vairee.ai or on our Discord channel (join here: [Discord Invite](https://discord.com/invite/TFpjCjdVJq))."
        ]
      },
      {
        q: "WHAT SHOULD I DO IF I LOSE MY FREE CREDIT WITHOUT GETTING AN ANALYSIS?",
        a: [
          "If you lose your free credit without receiving any analysis due to heavy traffic or processing issues, contact our support team immediately with the subject 'LOST CREDITS' at vairee@vairee.ai or on our Discord channel (join here https://discord.com/invite/TFpjCjdVJq) to resolve the issue.",
          "We are continuously working hard to prevent any traffic jams and ensure smooth processing. However, during peak times, especially when users in America are active, the system might slow down. In such cases, you can either wait a bit longer or try again at a different time. Typically, the analysis should complete within a few minutes. Please avoid refreshing your browser during this process, as it will restart the analysis, further delay the completion, and may cause a loss of credits."
        ]
      },
      {
        q: "WHAT INFORMATION WILL I RECEIVE FROM MY RESUME ANALYSIS?",
        a: [
          "The analysis provides the following insights:",
          "• Feeling: the general impression a professional, such as a recruiter or hiring manager, might have when they first see your resume.",
          "• Strengths: areas where your resume stands out.",
          "• Weaknesses: points that might be preventing your resume from winning an interview.",
          "• Improvements: suggestions based on best practices and conventions from experienced recruiters.",
          "• Additional: a brief summary of the analysis and key takeaways.",
          "• Next Career: recommendations for your next career move to become more attractive to potential employers.",
          "Explanation on Scoring: scoring resumes can be misleading because it often relies on standardized criteria that do not account for the unique aspects of each job applicant’s experiences and qualifications. A high score might suggest that a resume is universally strong, but in reality, it might only be suited to certain types of employers or industries. Conversely, a resume that scores lower might be perfectly aligned with the needs of a particular company. Therefore, our analysis is designed to provide more nuanced and relevant feedback, helping you tailor your resume to the specific roles and organizations you are targeting.",
          "By avoiding a simplistic scoring system, we ensure that you receive comprehensive and actionable advice that genuinely enhances your resume's effectiveness."
        ]
      },
      {
        q: "WHY DOES v a i r e e NOT USE A SCORE FOR RESUME ANALYSIS?",
        a: [
          "We believe that scoring resumes is inherently flawed and often irrelevant. Scoring systems tend to focus on very objective aspects, which can overlook the unique qualities and nuances of each resume. Moreover, a resume might score well for one company (Company A) and poorly for another (Company B), depending on their specific requirements and preferences.",
          "Each resume is unique and tailored to the individual's experiences, skills, and career goals. A scoring system can’t capture the subjective elements that make a resume stand out to different employers. Therefore, we focus on providing detailed insights and actionable feedback rather than a simple, and often misleading, score. Our goal is to help you understand how your resume is perceived and how you can improve it to meet the specific needs of various employers."
        ]
      },
      {
        q: "WHY IS THE RESULT FOR FIXING RESUME WEAKNESSES SOMETIMES SHORTER THAN THE ORIGINAL RESUME?",
        a: [
          "When you receive recommendations for fixing your resume's weaknesses, you might notice that the suggested improvements result in a shorter document. This happens because v a i r e e focuses on highlighting the most important and relevant information based on the analysis. Here’s why this approach is beneficial:",
          "1. Focused content: v a i r e e identifies and emphasizes the parts of your resume that make you stand out the most. This helps you concentrate on your strengths and ensures that these key points are prominent.",
          "2. Relevance: by omitting unnecessary or irrelevant information, your resume becomes more concise and impactful. This is crucial because recruiters and hiring managers often spend only a few seconds reviewing each resume. Clear and relevant information captures their attention more effectively.",
          "3. General recommendations: the analysis and recommendations provided by v a i r e e are based on best practices and conventions, not tailored to a specific job description. This means v a i r e e aims to create a strong general resume that can be easily customized for different job applications.",
          "4. Optimal length: the ideal resume length is typically one page, especially for those early in their careers. For individuals with extensive professional experience spanning several decades and significant career progression, a two-page resume may be appropriate. However, keeping it concise and focused is always beneficial.",
          "5. Suggestions, not mandates: remember, the recommendations from v a i r e e are suggestions to help you improve your resume. You should consider and implement these changes based on your judgment and the specific job you are applying for. After updating your resume with these suggestions, you can run another analysis to see how it improves.",
          "6. Customization limitations: v a i r e e cannot provide an exact unique resume because it lacks the ability to interview candidates or ask specific questions about their careers, achievements, and desires. Creating a fully customized resume that maximizes interview success requires personalized insights into your professional background and accomplishments.",
          "Future development: v a i r e e is actively developing a v a i r e e chatbot feature to gather additional information necessary for crafting unique and professional resumes tailored to individual career paths. This enhancement will enable v a i r e e to provide a more detailed and individual resume in the near future.",
          "By following these guidelines, your resume becomes a powerful tool that effectively communicates your qualifications and strengths to potential employers. v a i r e e aims to help you create a resume that is both compelling and professional, increasing your chances of landing an interview."
        ]
      },
      {
        q: "WHY CAN'T I FIX MY RESUME’S WEAKNESSES?",
        a: [
          "Fixing resume weaknesses requires 2 credits. If you can’t access this feature, it could be due to the following reasons:",
          "- You don’t have enough credits. Upgrade your plan to obtain more credits.",
          "- You have the JOB SEEKER plan, which only allows for reviewing/analyzing resumes, not fixing them.",
          "- If you have enough credits and are on the JOB SEEKER PRO or JOB SEEKER MAX plan and the process is not working, contact support immediately with the subject 'FIXING NOT WORKING' at ***vairee@vairee.ai*** or on our **Discord channel** (join here https://discord.com/invite/TFpjCjdVJq)."
        ]
      },
      {
        q: "HOW DOES v a i r e e HELP IN FIXING RESUME WEAKNESSES?",
        a: [
          "When you choose to FIX RESUME WEAKNESSES, v a i r e e takes the analysis, recommendations for improvements, and identified weaknesses to craft an improved version of your resume. Currently, the improved resume is provided in text format, not formatted. Future updates will include the ability to choose from several designs and edit your resume within the browser.",
          "Remember, the suggested improvements are based on the information in your resume. v a i r e e cannot ask additional questions to make your resume perfectly accurate but can suggest how to best express your experience."
        ]
      },
      {
        q: "WHAT IF THE 'FIX RESUME WEAKNESSES' PROCESS TAKES TOO LONG?",
        a: [
          "Sometimes, due to high traffic, the process might take longer. If it’s processing but never-ending, please contact support immediately with the subject 'FIXING NOT WORKING' at vairee@vairee.ai or on our Discord channel (join here https://discord.com/invite/TFpjCjdVJq).",
          "We are continuously working hard to prevent any traffic jams and ensure smooth processing. However, during peak times, especially when users in America are active, the system might slow down. In such cases, you can either wait a bit longer or try again at a different time. Typically, the analysis should complete within a few minutes. Please avoid refreshing your browser during this process, as it will restart the analysis and further delay the completion."
        ]
      },
      {
        q: "WHY CAN'T I FIX MY RESUME’S WEAKNESSES?",
        a: [
          "Fixing resume weaknesses requires 2 credits. If you can’t access this feature, it could be due to the following reasons:",
          "- You don’t have enough credits. Upgrade your plan to obtain more credits.",
          "- You have the JOB SEEKER plan, which only allows for reviewing/analyzing resumes, not fixing them.",
          "- If you have enough credits and are on the JOB SEEKER PRO or JOB SEEKER MAX plan and the process is not working, contact support immediately with the subject 'FIXING NOT WORKING' at vairee@vairee.ai or on our Discord channel (join here https://discord.com/invite/TFpjCjdVJq)."
        ]
      }
    ]
  },

  {
    title: "CONTACT v a i r e e",
    questions: [
      {
        q: "CONTACT INFORMATION",
        a: [
          "If you have any questions or concerns about using v a i r e e, please contact v a i r e e directly for further assistance.",
          "thank you for choosing v a i r e e",
          "- team of v a i r e e",
          "email: vairee@vairee.ai",
          "Discord channel: https://discord.com/invite/TFpjCjdVJq", 
          "date: July 08, 2024"
        ]
        }
    ]
  }
  
];