import Header from '../../../components/layouts/Header/Header';
import Hero from '../../../components/layouts/Hero/Hero';
import './Home.styles.css';

function Home() {
  return (
    <div className="home">
      <Header />
      <Hero />
    </div>
  );
}

export default Home;