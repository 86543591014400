import { Routes, Route } from 'react-router-dom';
import Home from '../components/pages/home/Home';
import About from '../components/pages/about/About';
import Blog from '../components/pages/blog/Blog';
import BlogPost from '../components/pages/blog/components/BlogPost/BlogPost';
import FAQ from '../components/pages/faq/FAQ';
import Contact from '../components/pages/contact/Contact';
import Pricing from '../components/pages/pricing/Pricing';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/pricing" element={<Pricing />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:category/:slug" element={<BlogPost />} />
      <Route path="/faq" element={<FAQ />} />
    </Routes>
  );
};

export default AppRoutes;