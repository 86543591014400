import { useRef, useEffect } from 'react';
import '../styles/Apps.styles.css';

function Apps() {
  const appCardsRef = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.2,
      rootMargin: '50px'
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in');
        }
      });
    }, observerOptions);

    appCardsRef.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  const aiApps = [
    {
      title: "Check Me",
      description: "get your application materials reviewed like a recruiter would.",
      link: "https://resume-check.vairee.ai/",
      image: "/images/about/little-app-check.webp"
    },
    {
      title: "Roast ME",
      description: "get a fun, honest and friendly roast of your resume or profile.",
      link: "https://roast-me.vairee.ai/",
      image: "/images/about/little-app-roast.webp"
    },
    {
      title: "Pre-screen Me",
      description: "AI-powered pre-screening for job applications.",
      link: "https://prescreen.vairee.ai/",
      image: "/images/about/little-app-prescreen.webp"
    }
  ];

  return (
    <section className="ai-apps">
      <div className="container">
        <div className="ai-apps-header">
          <h3>little career AI apps</h3>
          <p>experience the power of AI through our free tools. while these demonstrate basic AI capabilities, our professional solutions combine AI with expert insights for superior results.</p>
        </div>
        <div className="ai-apps-grid">
          {aiApps.map((app, index) => (
            <div 
              key={app.title} 
              className="app-card"
              ref={el => appCardsRef.current[index] = el}
            >
              <div className="app-image">
                <img src={app.image} alt={app.title} />
              </div>
              <div className="app-content">
                <h3>{app.title}</h3>
                <p>{app.description}</p>
                <div className="app-link-wrapper">
                  <a href={app.link} className="app-link" target="_blank" rel="noopener noreferrer">
                    TRY IT NOW
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="ai-apps-note">
          <h2>v a i r e e</h2><p> is <span>3 x more effective</span> by being trained by professional career experts.</p>
        </div>
      </div>
    </section>
  );
}

export default Apps;