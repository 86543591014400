import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade, Pagination } from 'swiper/modules';
import Button from '../../common/Button/Button';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import './Hero.styles.css';

const slides = [
  {
    background: '/images/hero/hero-slider-01.webp',
    subtitle: 'stop wasting time and',
    title: 'GET <span>HIRED</span>',
    description: "achieve high quality results with v a i r e e's affordable resume analysis",
    buttonText: 'ANALYZE MY RESUME',
    buttonLink: 'https://standoutcv.vairee.ai/'
  },
  {
    background: '/images/hero/hero-slider-03.webp',
    subtitle: 'double your interview chances and get a',
    title: '<span>DREAM</span> JOB',
    description: "sign up to access upcoming features: instant resume scoring, job description matching, and automated tailoring. all future updates are included with your free account.",
    buttonText: 'CREATE ACCOUNT',
    buttonLink: 'https://standoutcv.vairee.ai/'
  }
];

const Hero = () => {
  return (
    <section className="hero">
      <Swiper
        modules={[Autoplay, EffectFade, Pagination]}
        effect="fade"
        speed={1000}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        pagination={{
          el: '.hero-pagination',
          type: 'progressbar'
        }}
        loop={true}
        className="hero-slider"
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className="hero-slide">
              <div className="hero-background">
                <img src={slide.background} alt="" />
              </div>
              <div className="container">
                <div className="hero-content">
                  <p className="hero-subtitle">{slide.subtitle}</p>
                  <h1 dangerouslySetInnerHTML={{ __html: slide.title }} />
                  <p className="hero-description">{slide.description}</p>
                  <Button 
                    variant="hero" 
                    href={slide.buttonLink}
                  >
                    {slide.buttonText}
                  </Button>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="hero-pagination"></div>
      </Swiper>
    </section>
  );
};

export default Hero;