import { useState, useRef, useEffect } from 'react';
import Button from '../../../common/Button/Button';
import Card from '../../../common/Card/Card';
import PricingModal from '../../../common/PricingModal/PricingModal';
import './PricingUsers.styles.css';

function PricingJobSeeker() {
  const [isAnnual, setIsAnnual] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const cardRefs = useRef([]);

  const handleChoosePlan = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const observerOptions = {
      threshold: 0.2,
      rootMargin: '50px'
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in');
        }
      });
    }, observerOptions);

    cardRefs.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div className="billing-toggle-user">
        <span className={!isAnnual ? 'active' : ''}>Monthly</span>
        <button 
          className={`toggle-switch ${isAnnual ? 'annual' : ''}`}
          onClick={() => setIsAnnual(!isAnnual)}
          aria-label="Toggle billing period"
        >
          <span className="toggle-slider"></span>
        </button>
        <span className={isAnnual ? 'active' : ''}>Annual</span>
      </div>

      <div className={`plans-grid ${isAnnual ? 'annual-view' : 'monthly-view'}`}>
        <Card ref={el => cardRefs.current[0] = el} className="plan-card">
          <div className="plan-content">
            <h3>Job Seeker</h3>
            <div className="price">
              {isAnnual && <span className="original-price">$10 /month</span>}
              <span className="amount">${isAnnual ? '8' : '10'}</span>
              <span className="period">/month</span>
              <div>
                <span className="vat">${isAnnual ? '96' : '120'} /year, VAT Excl.</span>
              </div>
            </div>
            <ul className="features">
              <li>10 credits (review only)</li>
              <li>get resume feedback</li>
              <li>job application tracker</li>
              {!isAnnual && <li>credits reset monthly</li>}
              {isAnnual && <li>120 total credits for the year</li>}
            </ul>
          </div>
          <div className="card-dark-footer">
            <Button variant="hero" onClick={handleChoosePlan}>
              Choose Plan
            </Button>
          </div>
        </Card>

        <Card ref={el => cardRefs.current[1] = el} className="plan-card popular">
          <div className="popular-badge">Most Popular</div>
          <div className="plan-content">
            <h3>Job Seeker Pro</h3>
            <div className="price">
              {isAnnual && <span className="original-price">$20 /month</span>}
              <span className="amount">${isAnnual ? '16' : '20'}</span>
              <span className="period">/month</span>
              <div>
                <span className="vat">${isAnnual ? '192' : '240'} /year, VAT Excl.</span>
              </div>
            </div>
            <ul className="features">
              <li>20 credits + resume fixes</li>
              <li>comprehensive resume services</li>
              <li>job application tracker</li>
              {!isAnnual && <li>credits reset monthly</li>}
              {isAnnual && <li>240 total credits for the year</li>}
            </ul>
          </div>
          <div className="card-dark-footer">
            <Button variant="hero" onClick={handleChoosePlan}>
              Choose Plan
            </Button>
          </div>
        </Card>

        <Card ref={el => cardRefs.current[2] = el} className="plan-card">
          <div className="plan-content">
            <h3>Job Seeker Max</h3>
            <div className="price">
              {isAnnual && <span className="original-price">$80 /month</span>}
              <span className="amount">${isAnnual ? '64' : '80'}</span>
              <span className="period">/month</span>
              <div>
                <span className="vat">${isAnnual ? '768' : '960'} /year, VAT Excl.</span>
              </div>
            </div>
            <ul className="features">
              <li>80 credits + access to future features</li>
              <li>all Pro features included</li>
              <li>priority support</li>
              {!isAnnual && <li>credits reset monthly</li>}
              {isAnnual && <li>960 total credits for the year</li>}
            </ul>
          </div>
          <div className="card-dark-footer">
            <Button variant="hero" onClick={handleChoosePlan}>
              Choose Plan
            </Button>
          </div>
        </Card>
      </div>

      <PricingModal 
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
}

export default PricingJobSeeker;