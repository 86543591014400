import { useEffect } from 'react';

const CriticalCSS = () => {
  useEffect(() => {
    // Don't remove or modify any styles for now
  }, []);

  return null;
};

export default CriticalCSS;