import PropTypes from 'prop-types';
import './FAQSearch.styles.css';

const FAQSearch = ({
  searchTerm,
  searchHistory,
  onSearch,
  onSelectHistory,
  onClearHistory
}) => {
  return (
    <div className="search-container">
      <input
        type="text"
        placeholder="Search FAQ..."
        value={searchTerm}
        onChange={onSearch}
        className="search-input"
      />
      <i className="fa-solid fa-magnifying-glass"></i>
      
      {searchHistory.length > 0 && searchTerm && (
        <div className="search-history-dropdown">
          {searchHistory
            .filter(term => term.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((term, index) => (
              <button
                key={index}
                className="search-history-item"
                onClick={() => onSelectHistory(term)}
              >
                <i className="fa-solid fa-clock-rotate-left"></i>
                {term}
              </button>
            ))}
          <button 
            className="clear-history"
            onClick={onClearHistory}
          >
            Clear History
          </button>
        </div>
      )}
    </div>
  );
};

FAQSearch.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  searchHistory: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSearch: PropTypes.func.isRequired,
  onSelectHistory: PropTypes.func.isRequired,
  onClearHistory: PropTypes.func.isRequired
};

export default FAQSearch;