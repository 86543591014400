import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.styles.css';

const navigation = [
  { path: '/', label: 'Home' },
  { path: '/about', label: 'About' },
  { 
    path: 'https://standoutcv.vairee.ai', 
    label: 'Start Here', 
    isHighlighted: true,
    isExternal: true 
  },
  { path: '/pricing', label: 'Pricing' },
  { path: '/contact', label: 'Contact' },
  { path: '/blog', label: 'Blog' },
  { path: '/faq', label: 'FAQ' }
];

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'unset' : 'hidden';
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    document.body.style.overflow = 'unset';
  };

  return (
    <header className="header">
      <div className="container">
        <Link to="/" className="logo" onClick={closeMenu}>
          <img 
            src="/images/logo.webp" 
            alt="vairee logo - female face with connected net instead of brain" 
          />
        </Link>

        {/* Hamburger Menu Button */}
        <button 
          className={`hamburger ${isMenuOpen ? 'active' : ''}`}
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
          <span className="hamburger-line"></span>
        </button>

        <nav className={`nav ${isMenuOpen ? 'active' : ''}`}>
          <ul className="nav-list">
            {navigation.map(({ path, label, isHighlighted, isExternal }) => (
              <li key={path}>
                {isExternal ? (
                  <a 
                    href={path} 
                    className="nav-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={closeMenu}
                  >
                    {isHighlighted ? <span>{label}</span> : label}
                  </a>
                ) : (
                  <Link 
                    to={path} 
                    className="nav-link"
                    onClick={closeMenu}
                  >
                    {isHighlighted ? <span>{label}</span> : label}
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;