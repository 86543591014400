import Header from '../../layouts/Header/Header';
import Footer from '../../layouts/Footer/Footer';
import Services from './sections/components/Services';
import Vision from './sections/components/Vision';
import Apps from './sections/components/Apps';
import Features from './sections/components/Features';
import Team from './sections/components/Team';
import Testimonials from './sections/components/Testimonials';
import './About.styles.css';

function About() {
  return (
    <div className="about">
      <Header />
      <main>
        <section className="hero">
          <div className="hero-background">
            <img src="/images/hero/hero-slider-02.webp" alt="about v a i r e e" />
          </div>
          <div className="container">
            <div className="hero-content">
              <h1><span>IN</span>TRO</h1>
              <p>— a career is a path built on your decisions, the people you meet, and the information you get. with v a i r e e, reach the right contacts, gain the right advice, and ensure you have all the information to consider every possibility.</p>
            </div>
          </div>
        </section>

        <Apps />
        <Services />
        <Vision />    
        <Features />
        <Testimonials />
        <Team /> 
      </main>
      <Footer />
    </div>
  );
}

export default About;