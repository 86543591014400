import { useState, useEffect, useMemo } from 'react';
import Header from '../../../components/layouts/Header/Header';
import Footer from '../../../components/layouts/Footer/Footer';
import Highlight from '../../../components/common/Highlight/Highlight';
import FAQHero from './sections/components/FAQHero/FAQHero';
import FAQSearch from './sections/components/FAQSearch/FAQSearch';
import FAQCategories from './sections/components/FAQCategories/FAQCategories';
import FAQContent from './sections/components/FAQContent/FAQContent';
import { faqSections } from './sections/data/faqData';
import './FAQ.styles.css';

function FAQ() {
  const [expandedSections, setExpandedSections] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredSections, setFilteredSections] = useState(faqSections);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchHistory, setSearchHistory] = useState([]);

  const renderAnswer = (content, searchTerm) => {
    const getListClass = (text) => {
      if (text.startsWith('•')) return 'bullet-point';
      if (/^\d+[.]/.test(text)) return 'numbered';
      return '';
    };
  
    if (typeof content === 'string') {
      const className = getListClass(content);
      const cleanText = content.replace(/^[•\d+[.]]\s*/, '');
      
      return (
        <p className={className}>
          <Highlight text={cleanText} searchTerm={searchTerm} />
        </p>
      );
    }

    if (content.type === 'heading') {
      return (
        <h3 className="faq-plan-heading">
          <Highlight text={content.text} searchTerm={searchTerm} />
        </h3>
      );
    }

    if (content.type === 'plan') {
      return (
        <div className="faq-plan">
          <h4 className="faq-plan-title">
            <Highlight text={content.title} searchTerm={searchTerm} />
          </h4>
          <ul className="faq-plan-details">
            {content.details.map((detail, index) => (
              <li key={index}>
                {typeof detail === 'string' ? (
                  <Highlight text={detail} searchTerm={searchTerm} />
                ) : detail.type === 'price' ? (
                  <div className="faq-plan-price">
                    <span className="faq-plan-price-original">
                      <Highlight text={detail.original} searchTerm={searchTerm} />
                    </span>
                    <span className="faq-plan-price-discounted">
                      <Highlight text={detail.discounted} searchTerm={searchTerm} />
                    </span>
                  </div>
                ) : (
                  <Highlight text={detail} searchTerm={searchTerm} />
                )}
              </li>
            ))}
          </ul>
        </div>
      );
    }

    return null;
  };

  const categories = useMemo(() => 
    ['all', ...new Set(faqSections.map(section => section.title))], 
    []
  );

  // Load search history on mount
  useEffect(() => {
    const savedHistory = localStorage.getItem('faqSearchHistory');
    if (savedHistory) {
      setSearchHistory(JSON.parse(savedHistory));
    }
  }, []);

  // Filter sections based on search and category
  useEffect(() => {
    if (searchTerm.trim() === '' && selectedCategory === 'all') {
      setFilteredSections(faqSections);
      return;
    }

    let filtered = [...faqSections];

    // Filter by category
    if (selectedCategory !== 'all') {
      filtered = filtered.filter(section => section.title === selectedCategory);
    }

    // Filter by search term
    if (searchTerm.trim()) {
      const searchTermLower = searchTerm.toLowerCase();
      filtered = filtered.map(section => ({
        ...section,
        questions: section.questions.filter(item => {
          const questionMatch = item.q.toLowerCase().includes(searchTermLower);
          const answerMatch = Array.isArray(item.a) 
            ? item.a.some(content => {
                if (typeof content === 'string') {
                  return content.toLowerCase().includes(searchTermLower);
                }
                return false;
              })
            : item.a.toLowerCase().includes(searchTermLower);

          return questionMatch || answerMatch;
        })
      })).filter(section => section.questions.length > 0);

      // Auto-expand matching questions
      const newExpandedSections = {};
      filtered.forEach((section, sectionIndex) => {
        section.questions.forEach((_, questionIndex) => {
          newExpandedSections[`${sectionIndex}-${questionIndex}`] = true;
        });
      });
      setExpandedSections(newExpandedSections);
    }

    setFilteredSections(filtered);
  }, [searchTerm, selectedCategory]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectHistory = (term) => {
    setSearchTerm(term);
  };

  const clearSearchHistory = () => {
    setSearchHistory([]);
    localStorage.removeItem('faqSearchHistory');
  };

  const toggleQuestion = (sectionIndex, questionIndex) => {
    setExpandedSections(prev => ({
      ...prev,
      [`${sectionIndex}-${questionIndex}`]: !prev[`${sectionIndex}-${questionIndex}`]
    }));
  };

  return (
    <div className="faq">
      <Header />
      <main>
        <FAQHero />
        
        <section className="faq-section">
          <div className="container">
            <div className="faq-filters">
              <FAQSearch
                searchTerm={searchTerm}
                searchHistory={searchHistory}
                onSearch={handleSearch}
                onSelectHistory={handleSelectHistory}
                onClearHistory={clearSearchHistory}
              />
              
              <FAQCategories
                categories={categories}
                selectedCategory={selectedCategory}
                onSelectCategory={setSelectedCategory}
              />
            </div>

            <FAQContent
              sections={filteredSections}
              searchTerm={searchTerm}
              expandedSections={expandedSections}
              toggleQuestion={toggleQuestion}
              renderAnswer={renderAnswer} 
            />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default FAQ;