import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './Image.styles.css';

const Image = ({ 
  src, 
  alt, 
  className = '', 
  width,
  height,
  priority = false,
  objectFit = 'cover',
  quality = 75
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    if (priority) return;

    let currentImg = imgRef.current;
    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = img.dataset.src;
            observer.unobserve(img);
          }
        });
      },
      {
        rootMargin: '50px 0px',
        threshold: 0.01
      }
    );

    if (currentImg) {
      observer.observe(currentImg);
    }

    return () => {
      if (currentImg) {
        observer.unobserve(currentImg);
      }
    };
  }, [priority]);

  // Generate responsive sizes
  const sizes = width 
    ? `(max-width: ${width}px) 100vw, ${width}px` 
    : '100vw';

  // Generate srcSet for responsive images
  const generateSrcSet = (baseSrc, format) => {
    const widths = [320, 640, 960, 1280, 1920];
    const extension = format === 'webp' ? 'webp' : baseSrc.split('.').pop();
    
    return widths
      .filter(w => w <= (width || 1920))
      .map(w => {
        const optimizedSrc = baseSrc.replace(
          /\.(jpg|jpeg|png|webp)$/,
          `-${w}.${extension}`
        );
        return `${optimizedSrc} ${w}w`;
      })
      .join(', ');
  };

  const handleLoad = () => {
    setIsLoaded(true);
  };

  const handleError = () => {
    setError(true);
    // Fallback to original image if optimized version fails
    if (imgRef.current) {
      imgRef.current.src = src;
    }
  };

  const imageStyles = {
    width: width ? `${width}px` : '100%',
    height: height ? `${height}px` : 'auto',
    objectFit
  };

  return (
    <div 
      className={`image-container ${className} ${isLoaded ? 'loaded' : ''}`}
      style={{ width: imageStyles.width, height: imageStyles.height }}
    >
      <picture>
        {/* WebP format */}
        <source
          type="image/webp"
          srcSet={generateSrcSet(src, 'webp')}
          sizes={sizes}
        />
        {/* Original format */}
        <source
          type="image/jpeg"
          srcSet={generateSrcSet(src)}
          sizes={sizes}
        />
        <img
          ref={imgRef}
          src={priority ? src : ''}
          data-src={!priority ? src : undefined}
          alt={alt}
          width={width}
          height={height}
          loading={priority ? 'eager' : 'lazy'}
          onLoad={handleLoad}
          onError={handleError}
          style={imageStyles}
          className={`optimized-image ${isLoaded ? 'loaded' : ''}`}
        />
      </picture>
      {!isLoaded && !error && <div className="image-placeholder" />}
      {error && <div className="image-error">Image not available</div>}
    </div>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  priority: PropTypes.bool,
  objectFit: PropTypes.oneOf(['contain', 'cover', 'fill', 'none', 'scale-down']),
  quality: PropTypes.number
};

export default Image;