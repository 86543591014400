import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './Card.styles.css';

const Card = forwardRef(({ children, className = '', ...props }, ref) => {
  const cardClasses = `card ${className}`;
  
  return (
    <div className={cardClasses} ref={ref} {...props}>
      {children}
    </div>
  );
});

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Card.displayName = 'Card';

export default Card;