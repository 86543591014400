import './Footer.styles.css';

function Footer() {
  const socialLinks = [
    {
      name: 'Github',
      url: 'https://github.com/vairee-io',
      icon: 'fa-brands fa-github'
    },
    {
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/company/vairee',
      icon: 'fa-brands fa-linkedin'
    },
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/vairee/',
      icon: 'fa-brands fa-facebook'
    },
    {
      name: 'Twitter',
      url: 'https://twitter.com/_vairee',
      icon: 'fa-brands fa-twitter'
    },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/vairee.io/',
      icon: 'fa-brands fa-instagram'
    }
  ];

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-container">
          <a href="/" className="footer-logo">
            <img src="/images/logo.webp" alt="Vairee" />
          </a>
          <div className="footer-content">
            <p>© 2024 all rights reserved by v a i r e e</p>
          </div>
        </div>
        
        <div className="social-links">
          <ul>
            {socialLinks.map((link, index) => (
              <li key={index}>
                <a href={link.url} target="_blank" rel="noreferrer">
                  <p>{link.name}</p>
                  <i className={link.icon}></i>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;