import PropTypes from 'prop-types';
import './Button.styles.css';

const Button = ({
  variant = 'primary',
  children,
  className = '',
  href,
  ...props
}) => {
  const buttonClasses = `btn btn-${variant} ${className}`.trim();

  if (href) {
    return (
      <a href={href} className={buttonClasses} {...props}>
        {children}
      </a>
    );
  }

  return (
    <button className={buttonClasses} {...props}>
      {children}
    </button>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(['primary', 'secondary', 'hero']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool
};

export default Button;