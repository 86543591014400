import PropTypes from 'prop-types';
import './FAQCategories.styles.css';

const FAQCategories = ({ categories, selectedCategory, onSelectCategory }) => {
  return (
    <div className="category-filter">
      {categories.map(category => (
        <button
          key={category}
          className={`category-btn ${selectedCategory === category ? 'active' : ''}`}
          onClick={() => onSelectCategory(category)}
        >
          {category === 'all' ? 'All' : category}
        </button>
      ))}
    </div>
  );
};

FAQCategories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedCategory: PropTypes.string.isRequired,
  onSelectCategory: PropTypes.func.isRequired
};

export default FAQCategories;