export const jobSeekerPosts = [
  {
    id: 1,
    title: "Application Ghosting: 6 Tips for Job Seekers",
    slug: "avoid-recruiters-ghosting-your-application",
    date: "May 13, 2024",
    image: "/images/blog/jobSeeker/js01-ghosting-01.webp",
    category: "job-seeker",
    externalLink: "https://medium.com/@vairee/application-ghosting-6-tips-for-job-seekers-0c3e4e5ceb4b",
    highlights: [
      "Learn strategies how to avoid ghosting",
      "Customise your application",
      "How to use network to help",
    ],
    content: {
      intro: "In the world of job hunting, application ghosting, where candidates apply but receive no response, has become an all-too-common and incredibly frustrating phenomenon. You spend hours crafting the perfect resume and cover letter, submit your application, and then… crickets. No response, no acknowledgment, just silence. It's disheartening, demotivating, and, frankly, downright infuriating.",
      sections: [
        {
          title: "Follow Up Strategically",
          content: "One of the first steps in combating application ghosting is to follow up on your job applications. However, the key here is to do it strategically. Rather than sending a barrage of emails or making incessant phone calls, choose a polite and well-timed follow-up approach. Send a thank-you email shortly after applying to express your interest and inquire about the hiring timeline. Respect 2 -3 working days before approaching.\n\nIf you don't receive a response, consider sending a follow-up message after a reasonable period, usually one to two weeks later. Be concise, professional, and respectful in your communications."
        },
        {
          title: "Leverage LinkedIn and Networking",
          content: "In today's job market, networking plays a crucial role in securing opportunities. Utilise LinkedIn to connect with professionals in your industry and the companies you're interested in. Engage with their content, join relevant groups, and don't hesitate to send personalised connection requests.\n\nBuilding relationships and establishing a presence within your desired field can increase your chances of being noticed by hiring managers."
        },
        {
          title: "Customise Your Applications",
          content: "Sending out generic resumes and cover letters is a surefire way to get lost in the applicant pool. Tailor your applications to each specific job posting. Highlight your relevant skills and experiences that directly align with the job requirements.\n\nWhen employers see that you've taken the time to personalise your application, they're more likely to consider you a serious candidate."
        },
        {
          title: "Seek Feedback from Your Network",
          content: "Don't be afraid to seek feedback from your network, especially if you're experiencing consistent application ghosting. Reach out to mentors, colleagues, or friends who are willing to review your resume and provide constructive input. They might spot areas for improvement that you hadn't noticed before."
        },
        {
          title: "Stay Persistent and Positive",
          content: "Job searching can be a lengthy and emotionally taxing process. Application ghosting is undoubtedly frustrating, but it's essential to stay persistent and positive. Set realistic expectations and keep applying to positions that genuinely interest you.\n\nRemember that each rejection or lack of response is not a reflection of your worth or abilities. The right opportunity is out there; it might just take a bit of time to find it."
        },
        {
          title: "Reach out to an Independent Recruiter",
          content: "Consider reaching out to an independent recruiter who specialises in your expertise for advice and support. For example, if you are an IT specialist, feel free to contact Barbora Jensik for guidance. Recruiters have their networks and may have insights into companies that are ghosting you. However, ensure you connect with a recruiter who works for multiple clients, either under a recruitment agency brand or as a freelancer."
        },
        {
          title: "Conclusion",
          content: "Application ghosting is an unfortunate reality of the job search journey, but it doesn't have to define your experience. By implementing these strategies and maintaining a proactive and positive mindset, you can increase your chances of receiving responses from potential employers. Remember, you're not alone in this, and your dream job could be just around the corner. Keep pushing forward, keep improving your approach, and keep believing in yourself. Your next career opportunity awaits!"
        }
      ],
      announcement: {
        title: "Announcement",
        content: "We're thrilled to announce a significant milestone in our journey! After addressing various challenges and offering hopefully valuable advice to job seekers and hiring managers alike, we're excited to unveil the first version of v a i r e e.\n\nNow, job seekers can experience our cutting-edge resume analysis tool firsthand. We invite you to try it out and see how v a i r e e can transform your job search strategy.\n\nIf you'd like to give it a try, you can learn more here: https://vairee.ai/ and check out the product here: https://standoutcv.vairee.ai/."
      },
      vision: {
        title: "v a i r e e 's Vision",
        content: "v a i r e e will help you create a stunning profile based on which you can create custom resumes or fine-tune your online profiles. v a i r e e aims to be a place that assists you in designing the perfect profile and structuring your skillset within minutes, tailoring it for further needs.\n\nTo get more insights about v a i r e e and its abilities or to become an early adopter, send us a message at vairee@vairee.ai, social media facebook, linkedin or Discord server."
      },
      engagement: {
        question: "Have you ever experienced application ghosting during your job search?",
        callToAction: "We'd love to hear your stories and insights.\n\nHave you successfully employed any of the strategies mentioned in this article, or do you have additional recommendations from your own experiences? Share your thoughts with us in the comments below! Your valuable input can inspire and guide others in their career journeys. Together, we can conquer application ghosting and pave the way for career success."
      },
      credits: {
        images: "images credits to midjourney and Barbora Jensik as a prompt engineer"
      }
    }
  },

  {
    id: 2,
    title: "Deciphering Job Descriptions: Your Path to Landing the Perfect Job in 2024",
    slug: "deciphering-job-descriptions-perfect-job-2024",
    date: "May 15, 2024",
    image: "/images/blog/jobSeeker/js02-deciphering-01.webp",
    category: "job-seeker",
    externalLink: "https://medium.com/@vairee/deciphering-job-descriptions-your-path-to-landing-the-perfect-job-in-2024-b7c5af159ba2",
    highlights: [
      "Learn how to decode job descriptions effectively",
      "Tailor your resume to match job requirements",
      "Use networking to increase your chances of success"
    ],
    content: {
      intro: "2023 and 2024 have been tumultuous years for job seekers. Layoffs have become all too common, and the flood of applicants per job has made it harder than ever to stand out from the crowd. Ghosting has become a ubiquitous issue, leaving job seekers frustrated and uncertain. If you’re one of the many struggling to find your perfect job in this challenging environment, don’t despair. We’re here to help you decode job descriptions, stand out, and secure the job you’ve been dreaming of.",
      sections: [
        {
          title: "Decode Job Descriptions",
          content: "In today’s competitive job market, understanding what employers truly seek is the first step toward standing out. Job descriptions often serve as a wish list of skills and qualifications. To pass the initial screening, aligning your resume with the job description is crucial. One effective way to achieve this is by mirroring the language used in the job description."
        },
        {
          title: "Step 1: Analyzing the Job Description",
          content: "Use a detailed ChatGPT prompt to break down the job description and understand the skills and responsibilities the company is seeking. For example, ask ChatGPT to act as an experienced recruiter and provide a comprehensive description of the ideal candidate, focusing on mandatory and key skills."
        },
        {
          title: "Step 2: Resume Tune-Up",
          content: "Once you've analyzed the job description, use ChatGPT to align your resume with the skills and qualifications identified in the description. Ensure you focus on the language used in the job description, emphasizing your relevant skills. This will increase your chances of passing the initial screening."
        },
        {
          title: "Network and Follow-Up",
          content: "Networking plays a vital role in today’s job market. Cultivate meaningful connections with industry professionals and follow up to showcase your enthusiasm. Use LinkedIn and other relevant platforms to increase your visibility and create relationships with key decision-makers."
        },
        {
          title: "Stay Persistent and Positive",
          content: "Job searching is often a lengthy and emotionally taxing process. By tailoring your resume and effectively utilizing your network, you can break through the noise. Stay persistent and remember that your dream job is out there."
        },
        {
          title: "Conclusion",
          content: "By understanding job descriptions and tailoring your resume, you can break through the noise in today’s competitive job market. Make 2024 the year you land your dream job by following these strategies and staying positive throughout the process."
        }
      ],
      announcement: {
        title: "Announcement",
        content: "We’re thrilled to announce a significant milestone in our journey! After addressing various challenges and offering valuable advice to job seekers and hiring managers alike, we're excited to unveil the first version of **v a i r e e**.\n\nNow, job seekers can experience our cutting-edge resume analysis tool firsthand. We invite you to try it out and see how **v a i r e e** can transform your job search strategy.\n\nIf you'd like to give it a try, you can learn more here: https://vairee.ai/ and check out the product here: https://standoutcv.vairee.ai/."
      },
      vision: {
        title: "v a i r e e 's Vision",
        content: "**v a i r e e** will help you create a stunning profile, on which you can create custom resumes or fine-tune your online profiles. **v a i r e e** aims to be a place that assists you in designing the perfect profile and structuring your skillset within minutes, tailoring it for future needs.\n\nTo get more insights about **v a i r e e** and its abilities or to become an early adopter, send us a message at vairee@vairee.ai, social media facebook, linkedin or Discord server."
      },
      engagement: {
        question: "Have you ever struggled to decode a job description? How did you approach tailoring your resume?",
        callToAction: "We'd love to hear your thoughts! Have you successfully applied the strategies mentioned in this article, or do you have additional tips for decoding job descriptions and aligning your resume? Share your experiences with us in the comments below! Your insights can help others in their job search."
      },
      credits: {
        images: "images credits to midjourney and Barbora Jensik as a prompt engineer"
      }
    }
  },

  {
    id: 3,
    title: "Resume Clarity: Standing Out in a Competitive Job Market",
    slug: "resume-clarity-standing-out-competitive-job-market",
    date: "May 16, 2024",
    image: "/images/blog/jobSeeker/js03-clarity-01.webp",
    category: "job-seeker",
    externalLink: "https://medium.com/@vairee/resume-clarity-standing-out-in-a-competitive-job-market-84770630d250",
    highlights: [
      "Tips to craft a standout resume",
      "Tailor your resume for each job",
      "Showcase achievements with metrics"
    ],
    content: {
      intro: "Some time ago, I embarked on a journey, living in three different countries from scratch. Back then, social media wasn’t as prevalent, and job portals, though introducing openings, made receiving responses almost impossible. This struggle, often referred to as ghosting (read more here), remains a prevalent issue. What baffled me was the absence of a solution to make starting in a new job market much easier.",
      sections: [
        {
          title: "Facts to Bear in Mind When Crafting a Resume",
          content: "Recruiters and TA (Talent Acquisition) Members Scan Your Resume Within Seconds: Your resume receives only a brief glance. They look for keywords to determine resume action. Applicant Tracking Systems (ATS) play a key role: Keywords are vital as ATS sift through resumes, influencing their next course of action."
        },
        {
          title: "Significant Difference Between Resume and CV",
          content: "Understanding the disparity between a resume and a CV is essential. Delve deeper into this distinction here."
        },
        {
          title: "Motivation Letter: A Valuable Companion to Your Resume",
          content: "While some recruiters may not delve into it, a well-crafted motivation letter can be a game-changer. It serves as your ally, especially when a recruiter seeks assurance beyond the resume. Your resume is your primary sales pitch, and the motivation letter complements it effectively."
        },
        {
          title: "1. Start with a Great Resume Opener",
          content: "To emphasise your most valuable achievements that align with the specific job specification you are applying for, the first sentences should grab the attention of a recruiter. Keep it concise yet impactful, providing a snapshot of your professional journey."
        },
        {
          title: "2. Know Your Unique Selling Points (USPs)",
          content: "Identify your Unique Selling Points — the skills, experiences, and attributes that distinguish you. Ensure you highlight these in your resume, tailored to fit the job you're applying for."
        },
        {
          title: "3. Tailor Your Resume for Each Application",
          content: "Never use a one-size-fits-all approach. Tailor your resume for each job application by aligning it with the specific requirements of the role."
        },
        {
          title: "4. Quantify Your Achievements",
          content: "Use concrete numbers and metrics to quantify your achievements. Quantifying your impact adds credibility and gives potential employers a clear understanding of your tangible contributions."
        },
        {
          title: "5. Embrace a Clean and Readable Format",
          content: "Ensure your resume is clean, organised, and easy to read. Use a professional format with clear headings and bullet points."
        },
        {
          title: "6. Showcase Continuous Learning",
          content: "Highlight any certifications, workshops, or additional education you've undertaken to stay relevant in your field."
        },
        {
          title: "7. Leverage Keywords",
          content: "Incorporate industry-specific keywords relevant to the job description to increase your chances of your resume getting noticed by Applicant Tracking Systems (ATS)."
        },
        {
          title: "8. Seek Feedback",
          content: "Before sending out your resume, seek feedback from mentors, colleagues, or career advisors. Fresh perspectives can uncover areas for improvement."
        },
        {
          title: "9. Demonstrate Cultural Fit",
          content: "Convey your personality and values. Employers seek individuals who align with the company culture, so briefly mention interests or volunteer work."
        },
        {
          title: "10. Keep It Honest",
          content: "Integrity is crucial. Be truthful about your experiences and skills. Authenticity builds trust with potential employers."
        },
        {
          title: "Conclusion",
          content: "In a job market where first impressions matter more than ever, your resume serves as your introduction to prospective employers. By implementing these strategies, you’ll not only stand out but also increase your chances of securing that coveted position in today’s competitive landscape."
        }
      ],
      announcement: {
        title: "Announcement",
        content: "We’re thrilled to announce a significant milestone in our journey! After addressing various challenges and offering valuable advice to job seekers and hiring managers alike, we’re excited to unveil the first version of v a i r e e. Now, job seekers can experience our cutting-edge resume analysis tool firsthand. We invite you to try it out and see how v a i r e e can transform your job search strategy."
      },
      vision: {
        title: "v a i r e e 's Vision",
        content: "v a i r e e aims to bring an excellent user experience, making profiling a piece of cake for talent. At the same time, it helps you craft an impressive and honest resume while matching it to the job description, saving valuable time that talents would otherwise spend on rewriting their resumes."
      },
      engagement: {
        question: "What pains have you experienced when searching for a job?",
        callToAction: "We are curious about practices and finding solutions to avoid them in the long term. Share your thoughts in the comments below!"
      },
      credits: {
        images: "image credits to Midjourney and a prompt engineer Barbora Jensik"
      }
    }
  }
  
  

  
];



// {
//   id: null,
//   title: "",
//   slug: "",
//   date: "",
//   image: "/images/blog/",
//   category: "",
//   highlights: [
//       "",
//       "",
//       ""
//   ],
//   externalLink: null,
//   content: {
//       intro: "",
//       sections: [
//           {
//               title: "",
//               content: ""
//           }
//       ],
//       announcement: {
//           title: "Announcement",
//           content: ""
//       },
//       vision: {
//           title: "",
//           content: ""
//       },
//       engagement: {
//           question: "",
//           callToAction: ""
//       },
//       credits: {
//           images: ""
//       }
//   }
// }