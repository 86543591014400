import { useEffect, useRef, useState } from 'react';
import Header from '../../../components/layouts/Header/Header';
import Footer from '../../../components/layouts/Footer/Footer';
import Button from '../../../components/common/Button/Button';
import Card from '../../../components/common/Card/Card';
import './Contact.styles.css';

function Contact() {
  const contactCardsRef = useRef([]);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    subject: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  // Load reCAPTCHA script when component mounts
  useEffect(() => {
    // Load reCAPTCHA script only if it hasn't been loaded
    if (!window.grecaptcha) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=6Lc9P28qAAAAAEJH3ydyuyDC58AfrqkgTDHJkDun`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
    }

    // Cleanup
    return () => {
      // Optional: remove script when component unmounts
      const script = document.querySelector('script[src*="recaptcha"]');
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, []);

  // Animation observer effect
  useEffect(() => {
    const observerOptions = {
      threshold: 0.2,
      rootMargin: '50px'
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in');
        }
      });
    }, observerOptions);

    contactCardsRef.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  const contactOptions = [
    {
      title: "Discord server",
      description: "join v a i r e e's Discord server community to check news, announcements, and more.",
      link: "https://discord.gg/TFpjCjdVJq",
      bgText: "Discord"
    },
    {
      title: "vairee@vairee.ai",
      description: "don't miss out! be notified first when we launch new features. sign up for the waiting list by emailing us.",
      link: "mailto:vairee@vairee.ai",
      bgText: "Email"
    }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('sending');

    try {
      // Execute reCAPTCHA with your site key
      const token = await window.grecaptcha.execute('6Lc9P28qAAAAAEJH3ydyuyDC58AfrqkgTDHJkDun', {action: 'submit'});

      const response = await fetch('https://formspree.io/f/mwpkvjpd', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          'g-recaptcha-response': token
        }),
      });

      if (response.ok) {
        setStatus('success');
        setFormData({
          fullName: '',
          email: '',
          subject: '',
          message: ''
        });
      } else {
        setStatus('error');
      }
    } catch (error) {
      console.error('Error:', error);
      setStatus('error');
    }
  };

  return (
    <div className="contact">
      <Header />
      <main>
        <section className="hero">
          <div className="hero-background">
            <img src="/images/hero/hero-slider-04.webp" alt="Contact v a i r e e" />
          </div>
          <div className="container">
            <div className="hero-content">
              <h1>CONTACT</h1>
              <p>have questions, need assistance, or want to join our community? find the ways how to get in touch below, and let's connect!</p>
            </div>
          </div>
        </section>

        <section className="contact-section">
          <div className="container">
            <div className="contact-grid">
              {contactOptions.map((option, index) => (
                <Card 
                  key={option.title}
                  ref={el => contactCardsRef.current[index] = el}
                  className={`contact-card ${index}`}
                >
                  <div className="card-content">
                    <span className="bg-text">{option.bgText}</span>
                    <div className="card-info">
                      <h3>{option.title}</h3>
                      <p>{option.description}</p>
                    </div>
                    <div className="card-dark-footer">
                      <Button 
                        variant="hero"
                        href={option.link}
                        target="_blank" 
                        rel="noopener noreferrer"
                      >
                        CONNECT
                      </Button>
                    </div>
                  </div>
                </Card>
              ))}
            </div>
          </div>
        </section>

        <section className="contact-form-section">
          <div className="container">
            <div className="contact-form-container">
              <div className="contact-form-header">
                <h2>get in touch</h2>
                <p>stuck, have questions, or simply want to chat with us? we're here for you! fill out the form below, and we'll respond as soon as possible to support you.</p>
              </div>
              
              <form className="contact-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="fullName">full name</label>
                  <input
                    type="text"
                    id="fullName"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="email">email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="subject">subject</label>
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    value={formData.subject}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                
                <div className="form-group">
                  <label htmlFor="message">message</label>
                  <textarea
                    id="message"
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    required
                  />
                </div>

                <div className="form-submit">
                  <Button 
                    variant="hero"
                    type="submit"
                    disabled={status === 'sending'}
                  >
                    {status === 'sending' ? 'SENDING...' : 'SEND MESSAGE'}
                  </Button>
                </div>

                {status === 'success' && (
                  <div className="form-message success">
                    Thanks for your message! We'll get back to you soon.
                  </div>
                )}
                
                {status === 'error' && (
                  <div className="form-message error">
                    Oops! There was a problem sending your message. Please try again.
                  </div>
                )}

                <div className="recaptcha-terms">
                  This site is protected by reCAPTCHA and the Google{' '}
                  <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</a> and{' '}
                  <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> apply.
                </div>
              </form>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Contact;