import { useEffect } from 'react';
import './PricingModal.styles.css';

const PricingModal = ({ isOpen, onClose }) => {
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.body.style.overflow = 'unset';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h2>FREE access during MVP</h2>
          <button className="modal-close" onClick={onClose}>×</button>
        </div>
        <div className="modal-body">
          <p>our product is currently in MVP (Minimum Viable Product) phase and is available for FREE!</p>
          <p>we invite you to try our services and help us improve by providing valuable feedback.</p>
          <p>your input will help shape the future of v a i r e e.</p>
        </div>
        <div className="modal-footer">
          <a 
            href="https://standoutcv.vairee.ai/" 
            target="_blank" 
            rel="noopener noreferrer"
            className="hero-button"
          >
            Try For Free
          </a>
        </div>
      </div>
    </div>
  );
};

export default PricingModal;