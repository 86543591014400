import './FAQHero.styles.css';

const FAQHero = () => {
  return (
    <section className="hero">
      <div className="hero-background">
        <img src="/images/hero/hero-slider-02.webp" alt="FAQ v a i r e e" />
      </div>
      <div className="container">
        <div className="hero-content">
          <h1>FAQ</h1>
          <p>frequently asked questions about v a i r e e</p>
        </div>
      </div>
    </section>
  );
};

export default FAQHero;