import '../styles/Vision.styles.css';

function Vision() {
  return (
    <section className="vision">
      <div className="container">
        <div className="vision-content">
          <div className="vision-image image-container">
            <img src="/images/about/shaking.webp" alt="AI shaking hands with human" />
          </div>
          
          <h3 className="vision-title">v a i r e e's vision</h3>
          
          <div className="vision-box">
            <p className="vision-p">
              to bring a personal virtual career assistant into the pocket of <span>one million people</span>
            </p>
          </div>
          
          <div className="vision-description">
            <p className="content-text">
              we envision a future where career is accessible, personalized, and powered by well trained AI. by combining human expertise with artificial intelligence, we're creating tools that understand the nuances of professional growth and deliver actionable assistance for every career stage.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Vision;