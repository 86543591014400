import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import Image from '../../../../common/Image/Image';
import SEO from '../../../../SEO';
import BlogShare from '../BlogShare/BlogShare';
import BlogComments from '../BlogComments/BlogComments';
import { hiringManagerPosts } from '../../data/hiringManagerPosts';
import { jobSeekerPosts } from '../../data/jobSeekerPosts';
import './BlogPost.styles.css';

const BlogPost = () => {
  const { category, slug } = useParams();
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [prevPost, setPrevPost] = useState(null);
  const [nextPost, setNextPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    
    const loadPost = () => {
      setIsLoading(true);
      // Get posts based on category
      const categoryPosts = category === 'hiring-manager' 
        ? hiringManagerPosts 
        : jobSeekerPosts;
      
      const currentIndex = categoryPosts.findIndex(p => p.slug === slug);
      
      if (currentIndex === -1) {
        navigate('/blog');
        return;
      }

      const currentPost = categoryPosts[currentIndex];
      
      // Handle external links
      if (currentPost.externalLink) {
        window.location.href = currentPost.externalLink;
        return;
      }

      setPost(currentPost);
      setPrevPost(currentIndex > 0 ? categoryPosts[currentIndex - 1] : null);
      setNextPost(currentIndex < categoryPosts.length - 1 ? categoryPosts[currentIndex + 1] : null);
      setIsLoading(false);
    };

    loadPost();
  }, [category, slug, navigate]);

  if (isLoading || !post) {
    return <div className="blog-post-loading">Loading...</div>;
  }

  const formattedDate = new Date(post.date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <>
      <SEO 
        title={post.title}
        description={post.content.intro.substring(0, 155)}
        image={post.image}
        type="article"
        article={{
          title: post.title,
          image: post.image,
          date: post.date,
          description: post.content.intro.substring(0, 155)
        }}
        canonical={`https://vairee.ai/blog/${category}/${slug}`}
        keywords={[
          'career development',
          'job search',
          'professional growth',
          ...post.keywords || []
        ]}
        publishedTime={post.date}
        modifiedTime={post.lastModified || post.date}
      />

      <article className="blog-post" itemScope itemType="http://schema.org/BlogPosting">
        <header className="blog-post-header">
          <div className="container">
            <nav className="header-nav" aria-label="Blog navigation">
              <Link to="/blog" className="blog-logo">v a i r e e</Link>
              <div className="post-navigation">
                {prevPost && !prevPost.externalLink && (
                  <Link 
                    to={`/blog/${category}/${prevPost.slug}`} 
                    className="nav-link prev"
                    rel="prev"
                  >
                    <span className="nav-direction" aria-hidden="true">←</span>
                    <span className="nav-title">{prevPost.title}</span>
                  </Link>
                )}
                {nextPost && !nextPost.externalLink && (
                  <Link 
                    to={`/blog/${category}/${nextPost.slug}`} 
                    className="nav-link next"
                    rel="next"
                  >
                    <span className="nav-title">{nextPost.title}</span>
                    <span className="nav-direction" aria-hidden="true">→</span>
                  </Link>
                )}
              </div>
            </nav>
          </div>
        </header>

        <div className="blog-post-hero">
          <Image 
            src={post.image}
            alt={post.title}
            priority={true}
            width={1200}
            height={630}
          />
        </div>

        <div className="blog-post-content">
          <h1 itemProp="headline">{post.title}</h1>
          <div className="post-meta">
            <time dateTime={post.date} itemProp="datePublished">
              {formattedDate}
            </time>
            {post.readTime && (
              <span className="read-time">{post.readTime} min read</span>
            )}
          </div>

          <div className="blog-post-intro" itemProp="description">
            {post.content.intro}
          </div>

          <div className="post-sections">
            {post.content.sections.map((section, index) => (
              <section key={index} className="content-section">
                <h2>{section.title}</h2>
                {section.image && (
                  <div className="section-image">
                    <Image 
                      src={section.image}
                      alt={section.title}
                      width={800}
                      height={400}
                    />
                  </div>
                )}
                <div className="section-content">
                  {section.content}
                </div>
              </section>
            ))}
          </div>

          {post.content.announcement && (
            <section className="content-section">
              <h2>{post.content.announcement.title}</h2>
              <div className="section-content">
                {post.content.announcement.content}
              </div>
            </section>
          )}

          {post.content.vision && (
            <section className="content-section">
              <h2>{post.content.vision.title}</h2>
              <div className="section-content">
                {post.content.vision.content}
              </div>
            </section>
          )}

          <footer className="post-footer">
            <BlogShare 
              post={post}
              url={`https://vairee.ai/blog/${category}/${slug}`}
            />
            <div className="back-to-blog">
              <Link to="/blog" className="back-link">
                ← Back to Blog
              </Link>
            </div>
            <BlogComments />
          </footer>
        </div>
      </article>
    </>
  );
};

export default BlogPost;