import PropTypes from 'prop-types';
import FAQQuestion from './FAQQuestion';
import './FAQContent.styles.css';

const FAQContent = ({
  sections,
  searchTerm,
  expandedSections,
  toggleQuestion,
  renderAnswer // Make sure this is passed from parent
}) => {
  return (
    <div className="faq-grid">
      {sections.map((section, sectionIndex) => (
        <div key={section.title} className="faq-category">
          <h2>{section.title}</h2>
          <div className="faq-questions">
            {section.questions.map((question, questionIndex) => (
              <FAQQuestion
                key={question.q}
                question={question}
                searchTerm={searchTerm}
                isExpanded={expandedSections[`${sectionIndex}-${questionIndex}`]}
                onToggle={() => toggleQuestion(sectionIndex, questionIndex)}
                renderAnswer={renderAnswer} // Pass it to FAQQuestion
              />
            ))}
          </div>
        </div>
      ))}
      
      {sections.length === 0 && (
        <div className="no-results">
          <p>No matching questions found. Try a different search term.</p>
        </div>
      )}
    </div>
  );
};

FAQContent.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      questions: PropTypes.array.isRequired
    })
  ).isRequired,
  searchTerm: PropTypes.string.isRequired,
  expandedSections: PropTypes.object.isRequired,
  toggleQuestion: PropTypes.func.isRequired,
  renderAnswer: PropTypes.func.isRequired
};

export default FAQContent;