import { useEffect } from 'react';

const Performance = () => {
  useEffect(() => {
    // Leave empty for now
  }, []);

  return null;
};

export default Performance;