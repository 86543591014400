import { useState, useEffect } from 'react';
import '../styles/Testimonials.styles.css';

function Testimonials() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    const updateSlidesToShow = () => {
      if (window.innerWidth >= 1200) {
        setSlidesToShow(3);
      } else if (window.innerWidth >= 768) {
        setSlidesToShow(2);
      } else {
        setSlidesToShow(1);
      }
    };

    updateSlidesToShow();
    window.addEventListener('resize', updateSlidesToShow);
    return () => window.removeEventListener('resize', updateSlidesToShow);
  }, []);


  const testimonials = [
    {
      name: "Zara Khoury",
      position: "principal software architect",
      image: "/images/testimonials/zara.webp",
      text: "v a i r e e helped me refine my resume to highlight leadership achievements I hadn't considered important. the AI analysis pinpointed exactly what top tech companies look for. within weeks, I landed my dream role."
    },
    {
      name: "Kai Nakamura",
      position: "senior product manager",
      image: "/images/testimonials/kai.webp",
      text: "transitioning from engineering to product management was challenging. v a i r e e's insights helped me reframe my technical experience into product wins. the resume improvements were game-changing for my career pivot."
    },
    {
      name: "Luna Castellanos",
      position: "computer science graduate",
      image: "/images/testimonials/luna.webp",
      text: "as a fresh graduate, I was struggling to make my academic projects sound professional. v a i r e e transformed my resume and taught me how to present my experience. I secured a junior developer role at a top startup within a month!"
    },
    {
      name: "Aiden O'Reilly",
      position: "director of AI research",
      image: "/images/testimonials/aiden.webp",
      text: "the AI-powered analysis caught nuances in my research experience that I hadn't emphasized enough. v a i r e e helped me articulate my contributions to the field in a way that resonated with research-focused companies."
    }
  ];

  const handlePrevSlide = () => {
    setCurrentSlide(current => 
      current === 0 ? testimonials.length - slidesToShow : current - 1
    );
  };

  const handleNextSlide = () => {
    setCurrentSlide(current => 
      current === testimonials.length - slidesToShow ? 0 : current + 1
    );
  };

  return (
    <section className="testimonials">
      <div className="container">
        <div className="testimonials-header">
          <div className="testimonials-title">
            <h3>what our users say</h3>
          </div>
          <div className="testimonials-nav">
            <button onClick={handlePrevSlide} className="nav-button">
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <button onClick={handleNextSlide} className="nav-button">
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div className="testimonials-grid">
          {testimonials.slice(currentSlide, currentSlide + slidesToShow).map((testimonial) => (
            <div 
              key={testimonial.name} 
              className="testimonial-card"
            >
              <div className="testimonial-content">
                <div className="testimonial-top">
                  <div className="testimonial-image">
                    <img src={testimonial.image} alt={testimonial.name} />
                  </div>
                </div>
                <div className="testimonial-text">
                  <div className="testimonial-quote">
                    <p>"{testimonial.text}"</p>
                  </div>
                  <div className="testimonial-author">
                    <h4>{testimonial.name}</h4>
                    <span>{testimonial.position}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="testimonials-cta">
          <div className="testimonials-cta-box">
            <p>want to improve your career?</p>
            <a href="https://standoutcv.vairee.ai" className="hero-button">
              START HERE
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonials;