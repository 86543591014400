import { Link } from 'react-router-dom';
import './BlogCard.styles.css';

const BlogCard = ({ post }) => {
  // Helper function to handle the click event for the entire card
  const handleCardClick = (e) => {
    // Only handle click if it's not on the "Read More" link
    if (!e.target.classList.contains('blog-card-link')) {
      if (post.externalLink) {
        window.open(post.externalLink, '_blank', 'noopener noreferrer');
      } else {
        window.location.href = `/blog/${post.category}/${post.slug}`;
      }
    }
  };

  // Render the appropriate link component
  const ReadMoreLink = () => {
    if (post.externalLink) {
      return (
        <a 
          href={post.externalLink}
          className="blog-card-link"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          Read on Medium
        </a>
      );
    }
    return (
      <Link 
        to={`/blog/${post.category}/${post.slug}`}
        className="blog-card-link"
        onClick={(e) => e.stopPropagation()}
      >
        Read More
      </Link>
    );
  };

  return (
    <div className="blog-card" onClick={handleCardClick}>
      <div className="blog-card-image">
        <img src={post.image} alt={post.title} />
      </div>
      <div className="blog-card-content">
        <div className="blog-card-header">
          <h2 className="blog-card-title">{post.title}</h2>
          <div className="blog-card-date">{post.date}</div>
        </div>
        <div className="blog-card-intro">
          {post.content.intro.substring(0, 250)}...
        </div>
        <ul className="blog-card-highlights">
          {post.highlights.map((highlight, index) => (
            <li key={index}>{highlight}</li>
          ))}
        </ul>
        <ReadMoreLink />
      </div>
    </div>
  );
};

export default BlogCard;