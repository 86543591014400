import PropTypes from 'prop-types';
import Highlight from '../../../../../common/Highlight/Highlight';

const FAQQuestion = ({ 
  question, 
  searchTerm, 
  isExpanded, 
  onToggle,
  renderAnswer 
}) => {
  return (
    <div className={`faq-item ${isExpanded ? 'active' : ''}`}>
      <button 
        className="faq-question"
        onClick={onToggle}
      >
        <Highlight text={question.q} searchTerm={searchTerm} />
        <span className="faq-icon">
          {isExpanded ? '−' : '+'}
        </span>
      </button>
      <div className="faq-answer">
        {Array.isArray(question.a) ? (
          question.a.map((content, i) => (
            <div key={i}>
              {renderAnswer(content, searchTerm)}
            </div>
          ))
        ) : (
          <p>
            <Highlight text={question.a} searchTerm={searchTerm} />
          </p>
        )}
      </div>
    </div>
  );
};

FAQQuestion.propTypes = {
  question: PropTypes.shape({
    q: PropTypes.string.isRequired,
    a: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.array
    ]).isRequired
  }).isRequired,
  searchTerm: PropTypes.string.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  renderAnswer: PropTypes.func.isRequired
};

export default FAQQuestion;