import BlogCard from '../../components/BlogCard/BlogCard';
import { hiringManagerPosts } from '../../data/hiringManagerPosts';
import '../users.styles.css';

const HiringManagerBlog = ({ searchTerm }) => {
  const filteredPosts = hiringManagerPosts.filter(post => 
    post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    post.highlights.some(highlight => 
      highlight.toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  return (
    <div className="blog-section">
      <div className="blog-container">
        <div className="blog-grid">
          {filteredPosts.map(post => (
            <BlogCard key={post.id} post={post} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HiringManagerBlog;