import React from 'react';
import ReactDOM from 'react-dom/client';
// Global styles first
import './styles/abstracts/variables.css';
import './styles/abstracts/mixins.css';
import './styles/base/reset.css';
import './styles/base/typography.css';
import './styles/base/animations.css';
import './styles/utilities/helpers.css';
import './styles/index.css';
// Components last
import App from './App.js';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);