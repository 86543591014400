import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import Performance from './components/common/Performance/Performance';
import CriticalCSS from './components/common/CriticalCSS/CriticalCSS';
import AppRoutes from './routes';

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/vrs3dyr.css" />
      </Helmet>
      <Router>
        <Performance />
        <CriticalCSS />
        <AppRoutes />
      </Router>
    </HelmetProvider>
  );
}

export default App;