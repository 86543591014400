import { useState } from 'react';
import Header from '../../layouts/Header/Header';
import Footer from '../../layouts/Footer/Footer';
import BlogSearch from './components/BlogSearch/BlogSearch';
import JobSeekerBlog from './users/JobSeeker/JobSeekerBlog';
import HiringManagerBlog from './users/HiringManager/HiringManagerBlog';
import './Blog.styles.css';

function Blog() {
  const [category, setCategory] = useState('job-seeker');
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <div className="blog">
      <Header />
      <main>
        <section className="hero">
          <div className="hero-background">
            <img src="/images/hero/hero-slider-06.webp" alt="Blog v a i r e e" />
          </div>
          <div className="container">
            <div className="hero-content">
              <h1>BLOG</h1>
              <p>- discover insights, tips, and strategies for both job seekers and hiring managers</p>
            </div>
          </div>
        </section>

        <section className="blog-content">
          <div className="container">
            <div className="billing-toggle">
              <span className={category === 'job-seeker' ? 'active' : ''}>
                Job Seeker
              </span>
              <button 
                className={`toggle-switch ${category === 'hiring-manager' ? 'recruiter' : ''}`}
                onClick={() => setCategory(prev => 
                  prev === 'job-seeker' ? 'hiring-manager' : 'job-seeker'
                )}
                aria-label="Toggle category"
              >
                <span className="toggle-slider"></span>
              </button>
              <span className={category === 'hiring-manager' ? 'active' : ''}>
                Hiring Manager
              </span>
            </div>

            <BlogSearch value={searchTerm} onChange={handleSearch} />

            {category === 'hiring-manager' ? 
              <HiringManagerBlog searchTerm={searchTerm} /> : 
              <JobSeekerBlog searchTerm={searchTerm} />
            }
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Blog;