import { useState } from 'react';
import Header from '../../../components/layouts/Header/Header';
import Footer from '../../../components/layouts/Footer/Footer';
import Button from '../../../components/common/Button/Button';
import PricingJobSeeker from './users/PricingJobSeeker';
import PricingRecruiter from './users/PricingRecruiter';
import './Pricing.styles.css';

function Pricing() {
  const [isRecruiter, setIsRecruiter] = useState(false);

  return (
    <div className="pricing">
      <Header />
      <main>
        <section className="hero">
          <div className="hero-background">
            <img src="/images/hero/hero-slider-02.webp" alt="pricing v a i r e e" />
          </div>
          <div className="container">
            <div className="hero-content">
              <h1>PRICING</h1>
              <p>— a plan for every stage of your success!</p>
            </div>
          </div>
        </section>

        <section className="pricing-plans">
          <div className="container">
            <div className="pricing-header">
              <h2>subscription plans</h2>
              <p>whether you are actively looking for a new job, a professional opened to enter a dream job, or focusing on changing your career path, we have the right subscription for you or contact us!</p>
            </div>

            <div className="billing-toggle">
              <span className={!isRecruiter ? 'active' : ''}>Job Seeker</span>
              <button 
                className={`toggle-switch ${isRecruiter ? 'recruiter' : ''}`}
                onClick={() => setIsRecruiter(!isRecruiter)}
                aria-label="Toggle user type"
              >
                <span className="toggle-slider"></span>
              </button>
              <span className={isRecruiter ? 'active' : ''}>Recruiter</span>
            </div>

            {isRecruiter ? <PricingRecruiter /> : <PricingJobSeeker />}
          </div>
        </section>

        <section className="still-not-sure">
        <div className="container">
          <div className="not-sure-content">
            <h2>still not sure?</h2>
            <div className="not-sure-box">
              <p>book a demo with our team to see how v a i r e e can help you achieve your career goals</p>
              <Button variant="hero" href="/contact">Contact Us</Button>
            </div>
          </div>
        </div>
      </section>
      </main>
      <Footer />
    </div>
  );
}

export default Pricing;