import { useRef, useEffect } from 'react';
import '../styles/Team.styles.css';

function Team() {
  const teamCardsRef = useRef([]);

  useEffect(() => {
    const observerOptions = {
      threshold: 0.2,
      rootMargin: '50px'
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in');
        }
      });
    }, observerOptions);

    // Observe team cards
    teamCardsRef.current.forEach(ref => {
      if (ref) observer.observe(ref);
    });

    return () => observer.disconnect();
  }, []);

  const teamMembers = [
    {
      name: "Barush",
      role: "founder & product lead",
      intro: "full-stack principal IT Recruiter with a passion for AI and career development. leading the product vision of v a i r e e and design to revolutionize how people build their careers.",
      image: "/images/team/barush.webp",
      socials: [
        {
          url: "https://www.linkedin.com/in/barushbalazikova/",
          icon: "fa-linkedin"
        },
        {
          url: "https://github.com/abaddion",
          icon: "fa-github"
        },
        {
          url: "https://www.instagram.com/abaddion/",
          icon: "fa-instagram"
        },
        {
          url: "https://x.com/_abaddion",
          icon: "fa-x-twitter"
        }
      ]
    },
    {
      name: "David",
      role: "founder & technical lead",
      intro: "full-stack developer holding CTO roles with a passion for AI and inovation. leading the technical vision of v a i r e e to bring people closer to their dream job.",
      image: "/images/team/david.webp",
      socials: [
        {
          url: "https://www.linkedin.com/in/daviddutkovsky/",
          icon: "fa-linkedin"
        },
        {
          url: "https://github.com/balazikova",
          icon: "fa-github"
        }
      ]
    }
  ];

  return (
    <section className="team">
      <div className="container">
        <div className="team-header">
          <h3>meet the team</h3>
          <p>passionate experts committed to revolutionizing career development through AI innovation.</p>
        </div>
        <div className="team-grid">
          {teamMembers.map((member, index) => (
            <div 
              key={member.name} 
              className="team-card"
              ref={el => teamCardsRef.current[index] = el}
            >
              <div className="member-content">
                <div className="member-image">
                  <img src={member.image} alt={member.name} />
                </div>
                <div className="member-info">
                  <h3>{member.name}</h3>
                  <h4>{member.role}</h4>
                  <p>{member.intro}</p>
                </div>
                <div className="member-socials">
                  {member.socials.map((social, idx) => (
                    <a 
                      key={idx}
                      href={social.url} 
                      className="social-link" 
                      target="_blank" 
                      rel="noopener noreferrer"
                    >
                      <i className={`fa-brands ${social.icon}`}></i>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Team;